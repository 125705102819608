import React from "react"
import { Container } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"

import { MainLayout } from "../components/layouts/layout"

import SEO from "../components/seo"

export default function ErrorPage({ data }) {
  return (
    <MainLayout activeNav="">
      <SEO
        title="Siden kunne ikke findes"
        description="Låger Bordplade Greb og Håndtag Håndvaske KØKKENFORNYELSE MED OMTANKE! VI HAR ALLE ET ANSVAR OG SKAL BIDRAGE TIL EN BEDRE VERDEN. DERFOR GIVER DET OGSÅ GOD MENING, AT BIBEHOLDE DE EKSISTERENDE SKABE, NÅR DU SKAL HAVE FORNYET DIT KØKKEN. VED AT BEVARE DINE EKSISTERENDE SKABE, ER DU MED TIL AT REDUCERER RESURSESPILD. HVORFOR TAGE ET […]"
      />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="SIDEN KUNNE IKKE FINDES"
        description="Men mon ikke du finder noget andet.."
      />
      <Container fluid>
        <h3
          style={{
            fontStyle: "italic",
            marginTop: "100px",
            marginBottom: "100px",
            textAlign: "center",
          }}
        >
          Vi kan desværre ikke finde hvad du mangler, men håber dog du kigger
          dig omkring!
        </h3>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
  }
`
